import axios, { AxiosInstance, AxiosRequestHeaders } from "axios";
import { NextPageContext } from "next";
interface NetworkAdapterOpts {
    addContentType?: boolean;
    customContentType?: string;
    accessToken?: string;
    signal?: AbortSignal;
}

export interface IBDApi<T> {
    error?: string;
    errors?: string[]
    success: boolean;
    payload: T;
}

const networkAdapterOptsDefaults: NetworkAdapterOpts = {
    addContentType: true,
    customContentType: "application/json",
};

export function getApiUrl() {

    // Validate the kind of API URI to use
    if (typeof window === "undefined" || window === undefined|| !window) {
        // Else for NextJS serverside rendering, use the internal proxy URL
        return process.env.SERVER_APIURL ?? "https://staging.speedsig.com/api/v1";
    } else {
        // If it is client side, provide the next public client API URL
        return process.env.NEXT_PUBLIC_CLIENT_APIURL ?? "https://staging.speedsig.com/api/v1";
    }
}

export default function getNetworkAdapter(
    context?: NextPageContext | null,
    opts?: NetworkAdapterOpts,
    csrf?: string,
): AxiosInstance {
    const options = { ...networkAdapterOptsDefaults, ...opts };
    const headers: AxiosRequestHeaders = {} as AxiosRequestHeaders;

    if (options.accessToken) {
        headers["Authorization"] = `Bearer ${options.accessToken}`;
    }

    if (csrf) {
        // While Sanctum says it's a CSRF, it is actually an XSRF-Token...
        headers["X-XSRF-TOKEN"] = csrf;
    }

    const controller = axios.create({
        baseURL: getApiUrl(),
        responseType: "json",
        headers,
        withCredentials: true,
        // validateStatus: (status: number) => status === 200 || status === 422 || status === 201 || status === 400,
        validateStatus: (status: number) => true,
        // onUploadProgress: (ProgressEvent) => console.log(ProgressEvent),
        signal: options.signal || undefined,
    });

    // Reload Page if User is not logged in
    controller.interceptors.response.use(
        (val) => val,
        (error) => {
            // if (process.browser && error && error.response && error.response.status === 401) {
            //     // Unauthorised, potentially due to session expiry (or no session at all)
            //     Router.reload();
            // }
            // if (error.response.status === 402) {
            //     if (!process.browser) {
            //         return { error: "subscription-expired" };
            //     }
            //
            //     // Payment Required | Subscription or Trial Expired
            //     Router.push({
            //         pathname: pageRoutes.subscribe,
            //         query: {
            //             error: "subscription-expired",
            //         },
            //     }).then();
            // }
            // if (error.response.status === 419) {
            //     // CSRF Token Mismatch or Could not be verified (Laravel + Axios Specific)
            //     Router.push(pageRoutes.login).then();
            // }

            // Other errors such as 413, 422 etc. should use our generic error handling below
            throw error;
        },
    );

    return controller;
}
